import React from 'react'
import styles from './PageError.module.css'
import { Helmet } from 'react-helmet-async';

function ShowMessage() {
  document.title = "Error";
  return (
    <>
      <Helmet>
        <title>Error</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className={styles.main}>
        <section className={styles.container}>
          <h1>Error: 404</h1>
          <h2>Page Unavailable</h2>
          <p>Sorry, the page you are looking for is currently unavailable. Please check the URL or try again later.</p>
          <button onClick={() => window.location.href = "/"}>Back to home</button>
        </section>
      </main>
    </>

  )
}

export default ShowMessage