import React from 'react'

import styles from './Loading.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function Loading() {
    return (
        <section className={styles.container}>
            <FontAwesomeIcon icon={faSpinner} spin />
        </section>
    )
}

export default Loading