import React from 'react'
import styles from './Demo.module.css'

function Demo({ isShow }: { isShow: boolean }) {
    const openWindow = () => {
        window.open('https://www.figma.com/proto/xbvjwK4wZGydd520mtcFu8/UI?node-id=122-4567&node-type=canvas&t=bDgp7croKOd453S6-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=122%3A4567', '_blank', 'noopener,noreferrer');
      };
    return (
        <section className={`${styles.container} ${isShow ? styles.show : ''}`}>
            <div className={styles.section}>
                <button onClick={openWindow}>Check demo</button>
            </div>
        </section>
    )
}

export default Demo