import React from 'react'
import styles from './HamburgerMenu.module.css'

interface HamburgerMenuProps {
    isOpened: boolean,
}


function HamburgerMenu({ isOpened}: HamburgerMenuProps) {
    return (
        <div className={styles.menu}>
            <div className={`${styles.line} ${isOpened ? styles.opened : ''}`}></div>
            <div className={`${styles.line} ${isOpened ? styles.opened : ''}`}></div>
            <div className={`${styles.line} ${isOpened ? styles.opened : ''}`}></div>
        </div>
    );
}

export default HamburgerMenu