import React from 'react'
import styles from './ShowMessage.module.css'
import { Helmet } from 'react-helmet-async';

function ShowMessage() {
  document.title = "Message";
  return (
    <>
      <Helmet>
        <title>Alert</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <main className={styles.main}>
        <section className={styles.container}>
          <h1>🚧 Under Construction</h1>
          <p>We apologize for any inconvenience, but this page is currently under construction and will be available soon.</p>
          <button onClick={() => window.location.href = "/"}>Back to home</button>
        </section>
      </main>
    </>

  )
}

export default ShowMessage