import React from 'react'
import styles from './Footer.module.css'
import FooterLinks from '../footerlinks/FooterLinks';
import BottomFooters from '../bottomfooters/BottomFooters';
import Download from '../download/Download';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className={styles.container}>
            <div className={styles.divider}></div>
            <FooterLinks />
            <div className={styles.divider}></div>
            <Download/>
            <div className={styles.divider}></div>
            <BottomFooters/>
        </div>
    )
}

export default Footer