import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useEffect, useRef } from 'react';
import styles from './App.module.css'
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ShowMessage from './pages/message/ShowMessage';
import PageError from './pages/pageError/PageError';
import Loading from './pages/loading/Loading';
import Demo from './pages/demo/Demo';

const Home = lazy(() => import('./pages/home/Home'));
const About = lazy(() => import('./pages/about/About'));
const Services = lazy(() => import('./pages/services/Services'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Team = lazy(() => import('./pages/team/Team'));
const PrivacyPolicies = lazy(() => import('./pages/privacypolicies/PrivacyPolicies'));
const TermOfuse = lazy(() => import('./pages/termsofuse/TermsOfUse'));
const queryClient = new QueryClient();

function App() {
  const HomeLayout = () => {
    const { pathname } = useLocation();
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    }, [pathname]);

    return (
      <div className={styles.main} ref={contentRef}>
        <Demo isShow={pathname == "/"} />
        <header className={styles.header}>
          <Header />
        </header>
        <main className={styles.content}>
          <Suspense fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
              <Outlet />
            </QueryClientProvider>
          </Suspense>
        </main>
        <footer className={styles.footer}>
          <Footer />
        </footer>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
        </Route>
        <Route path="/privacy&policies" element={<PrivacyPolicies />} />
        <Route path="/termsofuse" element={<TermOfuse />} />
        <Route path="/alert" element={<ShowMessage />} />
        <Route path="/*" element={<PageError />} />
      </Routes>
    </Router>
  );
}

export default App;
