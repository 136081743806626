import React, { useEffect, useState } from 'react'
import styles from './Header.module.css'
import { Link, useLocation } from 'react-router-dom';
import HamburgerMenu from '../hamburger/HamburgerMenu';
import { links } from '../../data';


function Header() {
    const { pathname } = useLocation();
    const [isMenuOpened, setMenuOpened] = useState<boolean>(false);
    return (
        <div className={styles.container}>
            <Link to={'/'} className={`${styles.logo} ${isMenuOpened ? styles.hideLogo : ""}`}>
                <img src="/logo.png" alt="Logo" height={40} width={40} title='App icon' />
                <span>UraCab</span>
            </Link>
            {/* <a href='/' className={`${styles.logo} ${isMenuOpened ? styles.hideLogo : ""}`}>
                <img src="/logo.png" alt="Logo" height={40} width={40} title='App icon'/>
                <span>UraCab</span>
            </a> */}
            <div className={`${styles.links} ${isMenuOpened ? styles.isOpened : ""}`}>
                {
                    links.map((link, index) => (
                        <Link key={index} to={link.url} className={`${styles.link} ${pathname == link.url ? styles.activeUrl : ''}`} onClick={() => setMenuOpened(!isMenuOpened)}>{link.label}</Link>
                    ))
                }
            </div>
            <div className={styles.toggleBtn} onClick={() => setMenuOpened(!isMenuOpened)}>
                <HamburgerMenu isOpened={isMenuOpened} />
            </div>
        </div>
    )
}

export default Header