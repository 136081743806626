import React from 'react'
import styles from './Address.module.css'

function Address() {
  return (
    <div className={styles.container}>
        <h3>Address</h3>
        <p>UraCab Pvt. Ltd. New Secretariat Road, Capital Convention Centre, IDAN, Kohima, Nagaland , India.</p>
    </div>
  )
}

export default Address